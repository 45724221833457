import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { connect } from 'react-redux'
import { changeLANG, changeAMT } from "../state/store"


const Radio = ({ dispatch, lang, amt }) => {
    const resetAMT = () => {
        if (amt !== 'none') {
            dispatch(changeAMT('refresh'))
        }
    }
    return (
        <ButtonGroup onClick={resetAMT}>
            <Button style={{ backgroundColor: '#000080' }} onClick={() => dispatch(changeLANG('english'))} active={lang === 'english'}>English</Button>
            <Button style={{ backgroundColor: '#000080' }} onClick={() => dispatch(changeLANG('spanish'))} active={lang === 'spanish'}>Español</Button>
        </ButtonGroup>
    )
}


export default connect(state => ({
    lang: state.lang,
    amt: state.amt
}), null)(Radio)

