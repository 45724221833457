import React, { useState } from 'react';
import { Link } from 'gatsby'
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavbarText } from 'reactstrap';
import Radio from './radio'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import styles from '../styles/navbar.module.css'



const Example = ({ siteTitle, lang }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div >
            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Dancing+Script&display=swap" rel="stylesheet"></link>
                <link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet"></link>
            </Helmet>
            <Navbar style={{ backgroundColor: '#FFFEF2', fontFamily: "'Dancing Script', cursive" }} expand="md" fixed={'top'}>
                <Link to="/" style={{ color: '#d4af37', textDecoration: `none` }}>Ibis and Hunt</Link>
                <NavbarToggler className={styles.navbarIkon} onClick={toggle} />
                <Collapse className={styles.collapse} isOpen={isOpen} navbar>
                    <Nav className={`${styles.nav} mr-auto`} navbar>
                        <NavItem>
                            <Link className={styles.navLink} to="/story" style={{ textDecoration: `none` }} >{lang === 'english' ? 'Our Story' : 'Nuestra Historia'}</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={styles.navLink} to="/wedding" style={{ textDecoration: `none` }}>{lang === 'english' ? 'Wedding' : 'La Boda'}</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={styles.navLink} to="/wedding/#rsvp" style={{ textDecoration: `none` }}>{lang === 'english' ? 'RSVP' : 'Confirmar Asistencia'}</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={styles.navLink} to="/registry" style={{ textDecoration: `none` }}>{lang === 'english' ? 'Registry' : 'Registro de Bodas'}</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={styles.navLink} to="/contact" style={{ textDecoration: `none` }}>{lang === 'english' ? 'Contact' : 'Contacto'}</Link>
                        </NavItem>
                    </Nav>
                    <NavbarText>
                        <Radio />
                    </NavbarText>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default connect(state => ({
    lang: state.lang
}), null)(Example)

// export default Example;

//cuts

// color="light"

//#f88379

//#b3ecec