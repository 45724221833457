import PropTypes from "prop-types"
import React from 'react';
import Navy from './navbar'



const Header = ({ siteTitle }) => {
  return (
    <div>
      <Navy siteTitle={siteTitle} />
    </div>
  );
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
