import React from "react"
import { graphql } from "gatsby"
import Header from "./header"
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { changeLANG } from "../state/store"


class Layout extends React.Component {

  setLang(country) {
    country === 'US' ? this.props.dispatch(changeLANG('english')) : this.props.dispatch(changeLANG('spanish'))
  }

  componentDidMount() {
    if (this.props.lang === 'none') {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(this.locate)
      }
    }
  }

  locate = position => {
    var lat = position.coords.latitude;
    var lon = position.coords.longitude;
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${process.env.GATSBY_GOOGLE}`)
      .then((res) => {
        return res.json()
      }).then((res) => {
        this.setLang(res.results[0].address_components[6].short_name)
      })
  }

  render() {
    return (
      <div style={{ backgroundColor: '#FFFEF2' }}>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Dancing+Script&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet"></link>
        </Helmet>
        <Header siteTitle={data.site} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: `10000px`,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: '100px',
            backgroundColor: '#FFFEF2',
          }}
        >
          <main>{this.props.children}</main>
          <footer style={{ backgroundColor: '#FFFEF2', marginTop: '100px', color: '#000080', textAlign: 'center' }}>
            <p style={{ marginTop: '10px', display: 'inline-block' }}>huntCodes © {new Date().getFullYear()}, Built with Gatsby</p>
          </footer>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  lang: state.lang
}), null)(Layout)

export const data = graphql`
    query SiteTitleQuery {
      site: site {
        siteMetadata {
          title
        }
      }
    }
  `

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */